<template>
  <div>
    <!-- 新增备注 -->
    <h2 class="column-title">{{noteTitle}}</h2>
    <div>
      <el-row>
        <el-col :span="12" :offset="6">
          <el-input
            :placeholder="pleaseInputNotesText"
            suffix-icon="el-icon-notebook-1"
            v-model="notes"
          >
          </el-input>
        </el-col>
        <el-col :span="1">
          <el-button type="primary" @click="addNotes">{{competeText}}</el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 默认备注信息 -->
    <div style="margin-top: 20px;">
      <p v-for="(item, idx) in data" :key="idx">
        <el-checkbox v-model="isShowNoteChecked" v-if="showPrintCheckbox(item)" @change="printCheckboxChange">{{ item.replace("（可选打印）",'') }}</el-checkbox>
        <template v-else>
          {{ item }}
        </template>
      
    </p>
    </div>

    <!-- 新增备注信息⬆ -->
    <div style="margin-top: 20px;">
      <el-row v-for="(item, idx) in this.otherNotesData.list.notes.value" :key="idx" style="margin-top: 10px;">
        <el-col :span="12"> <div style="text-align: right;margin-top: 3px;"><b>{{item}}</b></div> </el-col>
        <el-col :span="1">
          <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="deleteNotes(idx)"></el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import * as defaultData from "../data/DefaultData";
export default {
  props: {
    selectProductType: String,
    otherNotesData:Object,
    language:String
  },
  inject:['setShowNoteValueMethod'],
  data() {
    return {
      data: [],
      notes: "",
      notesArray:[],
      isShowNoteChecked:false,
      noteTitle:this.language=='CN'?"备注":"Note",
      competeText:this.language=='CN'?"添加注释":"Complete",
      pleaseInputNotesText:this.language=='CN'?"请输入备注":"Please Input Notes",
    };
  },
  watch: {
    selectProductType(newValue) {
      this.data = this.getContentByType(newValue);
    },
  },
  created() {
    const productType = this.selectProductType;
    this.data = this.getContentByType(productType);
  },
  methods: {
    printCheckboxChange(val){
      this.setShowNoteValueMethod(val);
    },
    showPrintCheckbox(item) {
      return item.indexOf("（可选打印）") !== -1;
    },
    getContentByType(type) {
      let notes = [];
      if(this.language=='CN'){
        notes = defaultData.CNNotesDefaultData;
      }else{
        notes = defaultData.NotesDefaultData;
      }
      for (let data of notes) {
        if (data.type.includes(type)) {
          return data.content;
        }
      }
      return null; // 如果未找到匹配的类型，返回 null
    },
    addNotes() {
      
      let text = this.notes.toString();
      this.otherNotesData.list.notes.value.push(text);
      this.notes = "";
    },
    deleteNotes(idx){
      this.otherNotesData.list.notes.value.splice(idx,1);
    }
  },
};
</script>
